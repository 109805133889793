import React from 'react'
import { PrismicNextImage } from '@prismicio/next'

type HeroHomeImgProps = {
  classes: any
  src: any
  isMobile: boolean
  isDesktopXL: boolean
}

const HeroHomeImg = ({
  src,
  classes,
  isMobile,
  isDesktopXL,
}: HeroHomeImgProps) => {
  if (src && src.url) {
    let imgSrc = src
    if (isMobile) {
      imgSrc = src['md']
    } else if (!isDesktopXL) {
      imgSrc = src['lg']
    }
    return (
      <PrismicNextImage
        field={imgSrc}
        className={classes}
        alt={src?.alt ? src?.alt : ''}
        layout="fill"
        priority
        objectFit="cover"
        quality={100}
        imgixParams={{
          cs: 'srgb',
        }}
      />
    )
  } else {
    return null
  }
}

export default HeroHomeImg
